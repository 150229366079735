import React from 'react';

import video from '../images/loader.mp4';
import mobVid from '../images/loaderMob.mp4';
const LoaderScreen = () => {

  return (
    <div className="loader-div">
      <video className="web-vid" width="100%" muted autoPlay loop>
        <source src={video} type='video/mp4' />
      </video>
      <video className="mob-vid" width="100%" muted autoPlay loop>
        <source src={mobVid} type='video/mp4' />
      </video>
    </div>
  )
}
export default LoaderScreen;