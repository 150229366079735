import React, { Suspense, useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderScreen from "./pages/LoaderScreen";
import ScrollTop from "./ScrollTop";
import { IntlProvider } from "react-intl";

const Routes = React.lazy(() => import("./Routes"));
function App() {
  const [, setLoading] = useState(true);
  // const sleep = milliseconds => {
  //   return new Promise(resolve => setTimeout(resolve, milliseconds));
  // };
  // const wait = async (milliseconds = 2000) => {
  //   await sleep(milliseconds);
  //  setLoading(false)
  // };

  useEffect(() => {
    // wait(2000)
    setLoading(false);
  }, []);
  return [
    <IntlProvider locale="en" defaultLocale="en">
      <ToastContainer key="toasts" autoClose={3500} hideProgressBar />,
      <BrowserRouter>
        <ScrollTop />
        <Suspense fallback={<LoaderScreen />}>
          <Switch>
            <Routes />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </IntlProvider>,
  ];
}

export default App;
